<template>
  <router-view />
</template>

<script>
import redirectMixin from '@/utils/mixins/Redirect';


export default {
  name: 'ClassesBase',
  mixins: [redirectMixin],
  beforeRouteEnter(to, from, next) {
    // Wait for user to resolve to see if user is logged in jcervantes 07-23-2018
    next(vm => {
      if (!vm.$store.getters['user/getUser'] || (vm.user && (!vm.user.id || !vm.user.organization))) {
        vm.$store.dispatch('header/loadHeaderData').then(
          (response) => {
            if (vm.user && (!vm.user.is_logged_in || !vm.user.organization)) {
              if (!vm.user.is_logged_in) {
                // if not logged in kick him back to login page jcervanrtes 07-25-2018
                vm.redirectUrl(vm.SPENV.ACCOUNTS_API_URL  + '/login?return_url=' + window.location);
              }
              if (!vm.user.organization) {
                // if no organization kick back to dashboard page jcervanrtes 07-30-2018
                vm.redirectUrl(vm.SPENV.APP_URL);
              }
            }
          },
          (error) => {
            console.log(error);
          },
        );
      }
    });
  },
};
</script>
